.couponCodeDiv {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
  }
  
  .couponCodeDiv input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .couponCodeDiv button {
    white-space: nowrap;
  }