.categoryPage a {
    text-decoration: none;
    color: white;
    z-index: 999999999;
}


.categoryPage>div {
    /* top: 70px; */
}

.categoryPage p {
    margin: 0;
}

.categoryPage a:hover {
    transition-duration: 1s; 
     transform: scale(1.03);
     font-weight: 600;
}

/* Initial styles for rightIcon */
.rightIcon {
    color: white;
    opacity: 0;
    position: absolute;
    transform: translateX(0);
    transition: opacity 0.5s, transform 3s,color 3s; /* Transition opacity and transform */
  }
  
  /* Styles when the parent anchor tag is hovered */
  .categoryPage a:hover .rightIcon {
    color: rgb(212, 255, 0);
    opacity: 1;
    transform: translateX(20px); /* Slide right 10px */
  }
  .catImage:hover{
        transform: scale(1.03);
        transition-duration: 1.3s;
  }