.suggestions-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.suggestions-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.suggestion-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.suggestion-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.suggestion-details {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.suggestion-details h2 {
  margin: 0;
  font-size: 1.5em;
}

.suggestion-details p {
  margin: 5px 0;
  color: #555;
}

.suggestion-details p strong {
  font-weight: bold;
}
