.bottomSheet{
    z-index: 999999999;
}
.filter button {
    text-transform: capitalize;
    background: white;
}
.filter{
    font-size: 15px;
    font-weight: 400;
}
.filter button i{
    font-size: 12px;
    margin-left: 5px;
}
.filter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background:linear-gradient(45deg, #ffd700, #ffcc00, #ffd700);
}
/* CustomDropdown.module.css */
/* CustomDropdown.module.css */

.customDropdown {
    position: relative;
    display: inline-block;
    user-select: none;
  }
  
  .dropdownHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    min-width: 220px;
  }
  
  .sortByText {
    margin-right: 10px;
  }
  
  .arrow {
    font-size: 15px;
    margin: 0 10px;
    transition: transform 0.2s;
  }
  
  .rotateArrow {
    transform: rotate(180deg);
  }
  
  .dropdownList {
    display: none;
    position: absolute;
    z-index: 1;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%; /* Set the width to 100% */
    left: 0; /* Position it at the left edge of the dropdownHeader */
  }
  
  .customDropdown.open .dropdownList {
    display: block;
  }
  
  .dropdownList li {
    padding: 10px;
    cursor: pointer;
  }
  
  .dropdownList li:hover {
    background-color: #f0f0f0;
  }
  .bottomSheetFilters{
    margin-bottom: 20px;
  }
  .bottomSheet ul{
    padding-left: 0;
  }
  .bottomSheetFilters h3{
    font-size: 20px;
    margin-bottom: 15px;
  }
  .bottomSheetFilters label{
    font-size: 15px;
    margin-top: 5px;
  }
  .bottomSheetFilters label input{
    margin-right: 10px;
  }
  .applyFilterBtns{
    display: flex;
    justify-content: flex-end;
  }
  .applyFilterBtns button{
    margin-left: 15px;
  }
  @media only screen and (max-width: 767px){
    .filter{
        font-size: 12px;
        font-weight: 400;
    }
    .applyFilterBtns{
      position: fixed;
      width: 100%;
      left: 0;
      bottom: 0;
      z-index: 50;
    }
    .applyFilterBtns button{
      width: 100%;
      border-radius: 0;
      padding: 20px 0;
      margin-left: 0;
    }
  }
  