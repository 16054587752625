.userDropdown {
  display: none;
}
.progress-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: grey;
  z-index: 1000;
}

.progress-bar {
  height: 100%;
  background: rgb(0, 38, 53);
  width: 0%;
  transition: width 0.3s ease;
}

@media only screen and (min-width: 600px) {
  .navmenus {
    display: inherit !important;
  }
}

.userDropdownBtn:hover .userDropdown {
  display: block;
  display: flex;
  flex-direction: column;
  transition: all 1s;
}

.catListModal {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s, visibility 0s;
}
.catListModal > div {
  top: 90px;
  transition: top 1s;
}
.shop {
  border-radius: 0.5 rem;
  transition: border-radius 3s;
}
.shop:hover {
  border-radius: 0.5rem 0.5rem 0 0;
}
.shop:hover .catListModal,
.shop:hover .catListModal > div {
  /* display: flex; */
  /* transition-duration: 3s; */
  visibility: visible;
  opacity: 1;
  top: 78px;
  /* text-align: left !important; */
}
.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-bottom: 5px solid #e5e7eb;
}
.catListContainer {
  width: 50rem;
}

.mobileviewnav .active {
  border-top: solid blue !important;
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #9880ff;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}

.loginModal {
  opacity: 1;
  animation: fade 1s linear;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.footerStyle {
  text-align: center;
}
.checkout {
  margin-bottom: 4rem !important;
}
@media (min-width: 768px) {
  .footerStyle {
    text-align: left;
  }
  .checkout {
    margin-bottom: 0 !important;
  }
}
