.slider {
  -webkit-appearance: none;
  height: 12px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}
.image-gallery-icon{
  z-index: 1 !important;
}
.h-44 {
  height: 11rem;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 75%;
  background: #b7791f;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 18px;
  height: 18px;
  border-radius: 75%;
  background: #b7791f;
  cursor: pointer;
}

.carousel__prev {
  position: absolute;
  left: 0;
  top: 50%;
  color: #fff;
  margin-left: 10px;
  cursor: pointer;
  font-size: 25px;
}

.carousel__next {
  position: absolute;
  right: 0;
  top: 50%;
  color: #fff;
  margin-right: 10px;
  cursor: pointer;
  font-size: 25px;
}
